const axios = require("axios").default;
axios.defaults.withCredentials = true;
import moment from "moment";
export default {
    foo: function () {
        console.log("foo!");
        alert("foo!");
    },
    log: function (token, url, activity) {
        let vm = this;
        axios.defaults.headers.common = {
            Authorization: `Bearer ${token}`
        };
        axios
            .post(url + "/activity", {
                activity
            })
            .then(function (results) {});
    },
    formatFriendlyDate: function (d) {
        // let friendlyDate = moment(d)
        return moment(d).format("MM/DD/YYYY");
    },
    formatFriendlyDateTime: function (d) {
        // let friendlyDate = moment(d)
        return moment(d).format("MMMM Do YYYY, h:mm:ss a");
    }
};
